import Vue from 'vue';
import VueRouter from 'vue-router';
import Cookies from 'js-cookie'
import axios from 'axios'
import * as server from '.././server.js'

Vue.use(VueRouter);
let originPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    originPush.call(this, location, resolve, reject);
  }
  else {
    originPush.call(this, location, () => { }, () => { });
  }
}

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      name: 'hollow',
      path: '/hollowIndex',
      component: () => import('../components/pages/page_hollow/HollowIndex.vue'),
      meta: {
        isAuth: true
      },
      children: [{
        name: 'hollowList',
        path: '/hollow',
        component: () => import('../components/pages/page_hollow/HollowList.vue'),
        meta: {
          keepAlive: true,
          isAuth: true
        },
      },
      {
        name: 'hollowDetail',
        path: '/hollow/detail?=:id',
        component: () => import('../components/pages/page_hollow/HollowDetail.vue'),
        props: true,
        meta: {
          keepAlive: false,
          isAuth: true
        },
      }, {
        name: 'hollowNew',
        path: '/hollow/new',
        component: () => import('../components/pages/page_hollow/HollowNew.vue'),
        props: true,
        meta: {
          keepAlive: false,
          isAuth: true
        }
      }]
    }, {
      name: 'market',
      path: '/marketIndex',
      component: () => import('../components/pages/page_market/MarketIndex.vue'),
      meta: {
        isAuth: true
      },
      children: [{
        name: 'marketList',
        path: '/market',
        component: () => import('../components/pages/page_market/MarketList.vue'),
        props: true,
        meta: {
          isAuth: true,
          keepAlive: true
        },
      },
      {
        name: 'marketDetail',
        path: '/market/detail?=:id',
        component: () => import('../components/pages/page_market/MarketDetail.vue'),
        props: true,
        meta: {
          keepAlive: false,
          isAuth: true
        },
      },
      {
        name: 'marketNew',
        path: '/market/new',
        component: () => import('../components/pages/page_market/MarketNew.vue'),
        props: true,
        meta: {
          isAuth: true,
          keepAlive: false
        },
      }]
    }, {
      name: 'news',
      path: '/newsIndex',
      component: () => import('../components/pages/page_news/NewsIndex.vue'),
      meta: {
        isAuth: true
      },
      children: [{
        name: 'newsList',
        path: '/news',
        component: () => import('../components/pages/page_news/NewsList.vue'),
        props: true,
        meta: {
          isAuth: true,
          keepAlive: true
        },
      },
      {
        name: 'newsDetail',
        path: '/news/detail?=:id',
        component: () => import('../components/pages/page_news/NewsDetail.vue'),
        props: true,
        meta: {
          isAuth: true,
          keepAlive: false
        },
      }, {
        name: 'newsNew',
        path: '/news/new',
        component: () => import('../components/pages/page_news/NewsNew.vue'),
        props: true,
        meta: {
          isAuth: true,
          keepAlive: false,
          authLevel: 2
        }
      }]
    }, {
      name: 'lafIndex',
      path: '/laf',
      component: () => import('../components/pages/page_laf/LafIndex.vue'),
      meta: {
        isAuth: true
      },
      children: [{
        name: 'lafList',
        path: '/laf',
        component: () => import('../components/pages/page_laf/LafList.vue'),
        props: true,
        meta: {
          isAuth: true,
          keepAlive: true
        },
      },
      {
        name: 'lafDetail',
        path: '/laf/detail?=:id',
        component: () => import('../components/pages/page_laf/LafDetail.vue'),
        props: true,
        meta: {
          keepAlive: false,
          isAuth: true
        },
      },
      {
        name: 'lafNew',
        path: '/laf/new',
        component: () => import('../components/pages/page_laf/LafNew.vue'),
        props: true,
        meta: {
          isAuth: true,
          keepAlive: false
        },
      }]
    }, {
      name: 'user',
      path: '/userIndex',
      component: () => import('../components/pages/page_user/UserIndex.vue'),
      meta: {
        isAuth: true
      },
      children: [{
        name: 'userMe',
        path: '/user',
        component: () => import('../components/pages/page_user/UserMe.vue'),
        meta: {
          isAuth: true
        }
      },
      {
        name: 'userAnother',
        path: '/user/id?=:id',
        component: () => import('../components/pages/page_user/UserAnother.vue'),
        props: true,
        meta: {
          isAuth: true
        },
      },
      {
        name: 'userHistory',
        path: '/user/history',
        component: () => import('../components/pages/page_user/UserHistory.vue'),
        props: true,
        meta: {
          isAuth: true
        },
      }]
    }, {
      name: 'alert',
      path: '/alertIndex',
      component: () => import('../components/pages/page_alert/AlertIndex.vue'),
      meta: {
        isAuth: true,
        keepAlive: false
      },
      children: [{
        name: 'alertList',
        path: '/alert',
        component: () => import('../components/pages/page_alert/AlertList.vue'),
        meta: {
          isAuth: true
        }
      }, {
        name: 'alertDetail',
        path: '/alert/detail?=:id',
        component: () => import('../components/pages/page_alert/AlertDetail.vue'),
        meta: {
          isAuth: true
        }
      }]
    }, {
      name: 'admin',
      path: '/admin',
      component: () => import('../components/pages/page_admin/AdminIndex.vue'),
      meta: {
        isAuth: true,
        keepAlive: false,
        authLevel: 10
      }
    }, {
      name: 'redirect',
      path: '/rd?t=:type&id?=:id',
      props: true,
      meta: {
        isAuth: true,
        keepAlive: false
      },
      component: () => import('../components/solutions/RedirectPage.vue')
    }, {
      name: '404',
      path: '/404',
      meta: {
        isAuth: false,
        keepAlive: false
      },
      component: () => import('../components/solutions/NotFound.vue')
    }],
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.isAuth) {
    if (!Cookies.get('loginAs') || Cookies.get('loginCookie') != Cookies.get('loginAs')) {
      Cookies.remove('loginAs');
      Cookies.remove('loginCookie');
      Vue.prototype.goLogin();
      return;
    }
  }

  if (to.name == null) {
    if (to.path.includes('detail') || to.path.includes('user')) {
      let nextName = '';
      let param = parseInt(to.query[""]);
      if (to.path == '/hollow/detail') {
        nextName = 'hollowDetail';
      }
      else if (to.path == '/market/detail') {
        nextName = 'marketDetail';
      }
      else if (to.path == '/news/detail') {
        nextName = 'newsDetail';
      }
      else if (to.path == '/laf/detail') {
        nextName = 'lafDetail';
      }
      else if (to.path == '/user/id') {
        if (location.href.includes('id')) {
          nextName = 'userAnother'
        }
        else {
          nextName = 'userMe'
        }
      }
      else if (to.path == '/alert/id') {
        nextName = 'alertDetail';
      }
      next({ name: nextName, params: { id: param }, replace: true })
    }
    else {
      next({ name: 'hollowList' });
      setTimeout(() => {
        // location.reload();
      }, 250)
    }
    return
  }

  if (to.meta.isAuth && to.meta.authLevel) {
    const loadingInstance = Vue.prototype.loading(false);

    axios({
      method: 'post',
      url: server.default.user,
      data: new URLSearchParams({
        'type': 'me',
        'cookie': Cookies.get('loginAs')
      })
    }).then((response) => {
      loadingInstance.close();
      if (response.status != 200) {
        return;
      }
      else {
        if (response.data == 'USER_NOMATCH') {
          return;
        }
        else if (response.data.status == 'USER_FOUND') {
          if (response.data.userstatus >= to.meta.authLevel) {
            next();
          }
          else {
            return;
          }
        }
      }
    });
  }

  next()
})

export default router;