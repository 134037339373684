let mainServer = 'http://49.4.0.68:1000'
// let mainServer = 'http://172.26.16.33:1000';
// let mainServer = 'http://localhost:1000';

let servers = {
  server: mainServer,
  ping: mainServer + '/ping',

  register1: mainServer + '/register1',
  register2: mainServer + '/register2',
  register3: mainServer + '/register3',
  register4: mainServer + '/register4',
  login: mainServer + '/login',
  autoLogin: mainServer + '/autoLogin',

  user: mainServer + '/user',
  userHistory: mainServer + '/userHistory',

  changeUsername: mainServer + '/changeUsername',
  changePwd: mainServer + '/changePwd',
  getPurse: mainServer + '/getPurse',

  getHollowList: mainServer + '/getHollowList',
  getHollowItem: mainServer + '/getHollowItem',
  postHollow: mainServer + '/postHollow',
  postHollowComment: mainServer + '/postHollowComment',
  postHollowLike: mainServer + '/postHollowLike',
  deleteHollowItem: mainServer + '/deleteHollowItem',
  deleteHollowComment: mainServer + '/deleteHollowComment',

  getMarketList: mainServer + '/getMarketList',
  getMarketItem: mainServer + '/getMarketItem',
  postMarket: mainServer + '/postMarket',
  deleteMarketItem: mainServer + '/deleteMarketItem',

  getNewsList: mainServer + '/getNewsList',
  getNewsItem: mainServer + '/getNewsItem',
  postNews: mainServer + '/postNews',
  postNewsComment: mainServer + '/postNewsComment',
  postNewsLike: mainServer + '/postNewsLike',
  deleteNewsItem: mainServer + '/deleteNewsItem',
  deleteNewsComment: mainServer + '/deleteNewsComment',

  getLafList: mainServer + '/getLafList',
  getLafItem: mainServer + '/getLafItem',
  postLaf: mainServer + '/postLaf',
  deleteLafItem: mainServer + '/deleteLafItem',

  addPurse: mainServer + '/addPurse',
  takePurse: mainServer + '/takePurse',

  purchaseMarketItem: mainServer + '/purchaseMarketItem',
  claimLafItem: mainServer + '/claimLafItem',
  purchaseMarketPlace: mainServer + '/purchaseMarketPlace',

  newAdminWork: mainServer + '/newAdminWork',
  getAdminWork: mainServer + '/getAdminWork',
  completeAdminWork: mainServer + '/completeAdminWork',

  getAlertAmount: mainServer + '/getAlertAmount',
  getAlertList: mainServer + '/getAlertList',
  getAlertItem: mainServer + '/getAlertItem',
  updateAlertList: mainServer + '/updateAlertList',

  uploadImg: mainServer + '/uploadImg',
  removeImg: mainServer + '/removeImg',
}

export default servers;