import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueRouter from 'vue-router'
import router from './router'
import VueI18n from 'vue-i18n'
import ElementUI from 'element-ui'
import 'animate.css';
import 'element-ui/lib/theme-chalk/index.css'
import common from './common.js'
import 'element-ui/lib/theme-chalk/display.css';

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.prototype.$bus = new Vue()
Vue.use(common);
Vue.prototype.axios = axios;

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'cn_zh',
  messages: {
    cn_zh: require('./locale/cn_zh.json'),
    cn_tw: require('./locale/cn_tw.json'),
    en_us: require('./locale/en_us.json')
  }
})

new Vue({
  el: '#app',
  render: h => h(App),
  router: router,
  i18n: i18n,
  // beforeCreate () {
  //   Vue.prototype.$bus = this;
  // }
}).$mount('#app')

if (!localStorage.getItem('lang')) {
  localStorage.setItem('lang', 'cn_zh');
}
