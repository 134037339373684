import axios from 'axios'
import * as server from './server.js'
// import Cookies from 'js-cookie'
// import md5 from 'js-md5'
import { customAlphabet } from 'nanoid';
const nanoid = customAlphabet('1234567890', 9);

export default {
  install (Vue) {
    Vue.prototype.goLogin = function () {
      Vue.prototype.$bus.$emit('openLogin');
    }

    Vue.prototype.alert = function (message, title, callback) {
      this.$alert(message, title, {
        confirmButtonText: this.$t('System.Confirm'),
        callback: callback
      })
    }

    Vue.prototype.notify = function (title, message, type = 'success', duration = 3000) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        duration: duration
      })
    }

    Vue.prototype.loading = function (showText = true) {
      return this.$loading({
        lock: true,
        text: showText ? this.$t("System.Loading") : ''
      });
    }

    Vue.prototype.copyLink = function (message) {
      // navigator.clipboard.writeText(location.href).then(() => {
      //   this.$message.success(message);
      // })
      const textArea = document.createElement('textarea')
      textArea.value = location.href
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      this.$message.success(message);
      return new Promise((res, rej) => {
        document.execCommand('copy') ? res() : rej()
        textArea.remove()
      })
    }

    Vue.prototype.formatUserType = function (type, name) {
      switch (type) {
        case 0:
          return this.$t('User.Banned');
        case 1:
          return this.$t('User.AuthTypePerson');
        case 2:
          return this.$t('User.AuthType2') + ' (' + name + ')';
        case 3:
          return this.$t('User.AuthType3') + ' (' + name + ')';
        case 10:
          return this.$t('User.AuthTypeAdmin');
        default:
          return this.$t('User.AuthTypePerson');
      }
    }

    Vue.prototype.formatTime = function (time) {
      const date = new Date(time);

      const isToday = date.toDateString() === new Date().toDateString();
      if (isToday) {
        const diff = new Date() - date;
        const hours = Math.floor(diff / (1000 * 60 * 60));
        if (hours < 1) {
          return this.$t('General.JustNow');
        } else {
          return hours + this.$t('General.HoursAgo');
        }
      }

      const yesterday = new Date(new Date() - 24 * 60 * 60 * 1000);
      const isYesterday = date.toDateString() === yesterday.toDateString();
      if (isYesterday) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        return this.$t('General.Yesterday') + hours + ":" + minutes + ":" + seconds;
      }

      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return year + this.$t('General.Year') + month + this.$t('General.Month') + day + this.$t('General.Day');
    }

    Vue.prototype.formatUsername = function (username, isNick, isOwner) {
      if (isOwner) {
        return parseInt(isNick) ? this.$t('User.Nick') + ' (' + this.$t('User.You') + ')' : username;
      }
      return parseInt(isNick) ? this.$t('User.Nick') : username;
    }

    Vue.prototype.formatSchool = function (school) {
      switch (school) {
        case 0:
          return this.$t('User.UserSchool0');
        case 1:
          return this.$t('User.UserSchool1');
        case 2:
          return this.$t('User.UserSchool2');
        case 3:
          return this.$t('User.UserSchool3');
      }
      return this.$t('User.UserSchool0');
    }

    Vue.prototype.openUserPage = function (userid, isNicked = 0) {
      if (parseInt(isNicked)) {
        this.$message({
          message: '无法开启匿名用户的主页',
          type: 'warning'
        });
      }
      else {
        this.$router.push({
          name: 'userAnother',
          params: {
            id: userid
          }
        })
      }
    }

    Vue.prototype.report = function (usercookie, target, targetid, content, original) {
      const loadingInstance = this.loading();
      axios({
        method: 'post',
        url: server.default.newAdminWork,
        data: new URLSearchParams({
          'workid': nanoid(),
          'type': 0,
          'cookie': usercookie,
          'target': target,
          'targetid': targetid,
          'content': content,
          'original': original
        })
      }).then((response) => {
        loadingInstance.close();
        if (response.status != 200) {
          this.alert(this.$t('System.NetworkIssue'), this.$t('System.Error'))
        }
        else {
          if (response.data == 'ADMINWORK_WORKID_TAKEN') {
            this.report();
            return;
          }
          else if (response.data == 'ADMINWORK_NEW_COMPLETE') {
            this.$alert(this.$t('General.ReportSubmitted'), this.$t('System.Info'))
          }
          else {
            this.alert('Unexpected Response!', this.$t('System.Error'))
          }
        }
      })
    }

    Vue.prototype.getUserAvatar = function (id) {
      // let img = 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png';
      let img = server.default.server + '/img/user/' + id + '.png';
      return img;
    }

    Vue.prototype.redirect = function (type, id = 0) {
      this.$router.push({
        name: 'redirect',
        params: {
          type: type,
          id: id
        },
      })
    }

    Vue.prototype.notfound = function () {
      this.$router.push({
        name: '404'
      })
    }

    Vue.prototype.getIfAdmin = function () {
      if (sessionStorage.getItem('ia') != null && sessionStorage.getItem('ia') != undefined) {
        if (sessionStorage.getItem('ia') == 'true') return true;
        else return false;
      }
      return false;
    }

    Vue.prototype.switchLang = function (lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('lang', lang);
      setTimeout(() => {
        this.$notify({
          title: this.$t('System.Info'),
          message: this.$t('Lang.OnSwitch'),
          type: 'warning',
          duration: 5000
        })
      }, 250);
    }
  }
}