<template>
  <div id="app">
    <span class='background'></span>
    <main-frame></main-frame>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
    MainFrame: () => import('./components/MainFrame.vue')
  },
}
</script>

<style>
#app {
  text-align: center;
  background-color: rgb(235, 234, 234);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("@/../public/assets/jnu.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  background-attachment: fixed;
  opacity: 0.06;
  z-index: -1;
}
</style>
